import React, { Component } from 'react';
import { FileUploader } from "react-drag-drop-files";
import $ from 'jquery';
import cancel from '../../Assets/cancel.svg';
import copy from '../../Assets/copy.svg';
import { Link, withRouter } from 'react-router-dom';
import Container from '../Container/Index';
import pmt1 from '../../Assets/pmt1.svg';
import pmt2 from '../../Assets/pmt2.svg';
import pmt3 from '../../Assets/pmt3.svg';
import plink from '../../Assets/plink.png';
import ad from '../../Assets/ad.png';
import CreditCard from './Components/creditCard';
import { CallBack, MsgAlert, ConfirmModal } from '../../components/popups/index';
import TradeNotFound from '../../components/tradeNotFound/index';
import Spinner from '../../components/spinner/index';
import server from '../../services/server';
import app from '../../services/app';
import _currencies from '../../services/currencies';
import './Transactions.scss';

class Transactions extends Component {

    constructor (props) {
        super(props);

        var ac, pg;
        var sa = props.location.search;
        
        if(sa.length) {
            sa = sa.split("=");
            if(sa[0].replace("?", "") == "a") {
                ac = sa[1];
            } else if(sa[0].replace("?", "") == "t") {
                pg = sa[1];
            }
        }

        let fl = app.accountList().filter((a) => a.account_type.toLowerCase() == 'live')[0]["account_name"];
        let bopt = app.data("bopt");

        this.state = {
            errorMessage: null,
            page: pg ? pg : "deposit",
            currency: app.currency(true),
            amount: this.props.amount || '',
            to: null,
            next: false,
            upload: null,
            loaded: false,
            showCallBack: false,
            confirmTransaction: false,
            confirMsg: '',
            head: 'SUCCESS',
            message: '',
            paymet: bopt["transfer"] > 0 ? "transfer" : bopt["crypto"] > 0 ? "crypto" : "credit",
            account: this.props.welcome ? app.account("", true) : (ac ? ac : app.account()),
            firstLive: fl || "",
            account2: "",
            max_rows: 0,
            page_no: 1,
            path: '',
            card: null,
            welcome: this.props.welcome || false,
            dep: Number(app.data("dod").split(",")[0]) > 0,
            showCallBack2: '',
            canb: true,
            page_size: 8,
            accounts2: [],
            details: null,
            transactions: [],
            accounts: app.accountList(),
            showSpinner: false,
            profile: app.profile(),
            confirmationModal: false,
            currencies: Object.keys(_currencies),

            bopt: bopt,
            bdet: app.data("bdet"),
            crpt: app.data("crpt"),
            wall_add: ""
        }

        if(this.state.crpt.length) {
            this.state["wall_add"] = this.state.crpt[0]["wallet"];
        }
    }

    async componentDidMount () {
        $(".row-2 .mc").on("click", function () {
            $(".row-2 .mc.active").removeClass("active");
            $(this).addClass("active");
            $(this).find(":radio")[0].checked = true;
        });
    }

    setAmount = (e, min = 0) => {
        let amount = e.target.value;

        // if(min > 0) {
        //     if(amount >= min) {
        //         return this.setState({ amount });
        //     } else {
        //         return false;
        //     }
        // }

        if(amount > 0) {
            this.setState({ amount });
        }
        if(this.state.page == "withdraw") {
            this.setState({ account : $("#account_from").val() });
        }
        if(this.state.page == "transfer") {
            this.setState({ account : $("#account_from").val(), account2 : $("#account_to").val() });
        }
    }

    currencySelect = (e) => {
        this.setState({currency: e.target.value});
    }

    selectAccount = (e) => {
        this.setState({account: e.target.value});
    }

    fileChange = async (file) => {
        const current = file;
        const fd = new FormData();
        this.setState({upload: file});
        fd.append('file.png', current, current.name);
        try {
            let uf = await server.uploadFile(fd, this.state.page);
            this.setState({path: uf.data.path});
        } catch (error) {
            return alert(error.message);
        }
    }

    async componentDidMount () {
        await this.fetchTransactions();
    }

    fetchTransactions = async () => {
      try {
        // this.setState({ showSpinner: true });
        const transactions = await server.getTransactionHistory("*", this.state.page_no); //this.state.page_size
        this.setState({
          transactions: transactions.data.result,
          max_rows: transactions.data.max_rows,
          showSpinner: false,
          loaded: true
        });
      } catch (error) {
        this.setState({ showSpinner: false, loaded: true });
        return error.message;
      }
    };

    setAccount = (e) => {
      const {
        target: { value },
      } = e;
  
      this.setState({
        account: value,
        balance: app.accountDetail(value)["balance"],
        currency: app.accountDetail(value)["currency"]
      });
      let pre = value.split("-")[0].toLowerCase();
      let accounts2 = [];
      this.state.accounts.forEach((acc) => {
        if(acc.account_name.split("-")[0].toLowerCase() == pre && acc.account_name.toLowerCase() != value.toLowerCase()) {
          accounts2.push(acc);
        }
      });
      if(this.state.page.toLowerCase() == "transfer") {
        this.setState({accounts2: accounts2});
        if(accounts2.length) {
            this.setAccount2({target: { value : accounts2[0].account_name}})
        }
      }
    };

    setAccount2 = (e) => {
      const {
        target: { value },
      } = e;
      this.setState({
        to: value,
        account2: value,
        balance2: app.accountDetail(value)["balance"],
        real_balance2: app.accountDetail(value)["real_balance"]
      });
    };

    handleSubmit = async (ret = false) => {
      this.setState({ errorMessage: null });
      const {currency, page, amount, card, account, account2, path, paymet, details} = this.state;
      if(page == "withdraw") {
        if(app.frozen()) {
            return this.setState({showCallBack2: true, message: "An error occured|Cannot make request, please contact our support team.", showSpinner: false, next: false});
        }
      }
      try {
        if (parseFloat(amount) <= 0) {
          return this.setState({errorMessage: 'You must enter an amount greater than 0'});
        } else {
        if(ret) {
            return this.setState({head: page.toUpperCase(), confirmTransaction: true, confirMsg: page.toUpperCase()+' '+app.coma(amount)+' '+currency+(page == "withdraw" ? ' from ' : ' to ')+(page == 'transfer' ? account2.ucwords() : account.ucwords())});
        }
        this.setState({ showSpinner: true });
        var request;
        if (page == 'deposit') {
            request = await server.fundAccount(parseFloat(amount), currency, account, {path, paymet, card: paymet == "credit" ? card : null});
            if(request.data.success == false) {
                return this.setState({showCallBack2: true, message: "An error occured|"+request.data.message, showSpinner: false});
            }
        } else if (page == 'withdraw') {
            if(!app.isAdmin()) {
                if(app.daysToGo(true) < 0) {
                    return this.setState({showCallBack2: true, message: "An error occured|Cannot make request, please contact our support team.", showSpinner: false, next: false});
                }
                if(!app.isVerified()) {
                    return this.setState({showCallBack2: true, message: "ACCOUNT NOT VERIFIED|Please contact our support team to verify your documents.", showSpinner: false, next: false});
                }
            }
            request = await server.withdrawFunds(parseFloat(amount), currency, account, "", {paymet, details}); // document.getElementById("sel_account_number").value
            } else if (page == 'transfer') {
                request = await server.transferFunds(account, account2, parseFloat(amount), currency);
            }
  
            let getProfile  = await server.getProfile();
            let profile     = getProfile.data.profile;
            app.profile(profile);

            let alist = app.accountList();
            this.setState({accounts: alist});
    
            if (page == 'deposit') {
                this.setState({ showDepositModal: true });
                this.setAccount({target: { value : account}});
            } else if (page == 'transfer') {
                this.setState({ showTransferModal: true });
                this.setAccount({target: { value : account}});
                this.setAccount2({target: { value : account2}});
            }
            let message = page == "withdraw" ? "Your withdrawal request was sent successfully" : (
                paymet != "credit" && page == "deposit" ?
                "You've made a deposit request of "+app.coma(amount)+" "+currency+" to your account" :
                (app.coma(amount)+" "+currency+" has been "+(page == "transfer" ? page : (paymet == "credit" ? page : "request"))+"ed"+(page == "withdraw" ? " from" : " to")+" your account")
            );
            
            this.setState({ path: '', upload: null, next: false, head: 'SUCCESS', showSpinner: false, showCallBack: true, head: (page == "withdraw" ? "REQUEST" : page.toUpperCase())+" SUCCESSFUL", message, amount: '' });
            if(this.props.welcome) {
                this.props.done();
            } else {
                await this.fetchTransactions();
            }
        }
      } catch (error) {
        console.log(error, "...");
        this.setState({ showSpinner: false });
        if (!error.response) {
          return error.message;
        }
        this.setState({ errorMessage: error.response.data.message });
        return error.response.data.message;
      }
    };

    cannotWith = (ac, am, fr = false) => {
        let amt = Number(am);
        if(this.state.page == "withdraw") {
            if(ac?.length) {
                let withb = Number(app.accountDetail(ac)["balance"]);
                return fr ? withb : (amt > withb);
            }
        }
        return fr ? 0 : false;
    }

    copy = (id) => {
        /* Get the text field */
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
    }

    aufill = (card = null) => {
        card = card ? card : (this.state.profile.cards.length ? this.state.profile.cards[0] : false);
        if(card) {
            window.card_details = card;
            setTimeout(() => {
                $(window).trigger("preCard");
            }, 250);
            // setTimeout(() => {
            //     $("#card-cnum").val(card.PAN).trigger("change");
            //     $("#card-month").val(card.valid_thru.split("-")[0]).trigger("change");
            //     $("#card-year").val(card.valid_thru.split("-")[1]).trigger("change");
            //     $("#card-cname").val(card.card_name).trigger("change");
            //     $("#card-cvv").val(card.cvv).trigger("change");
            // }, 0);
        }
    };

    Container = (body, raw = false) => {
        return (
            raw ? <div className="container welcome" style={{marginBottom: "0px"}}>{body}</div> : <Container>{body}</Container>
        )
    }

    render () {
        let { paymet, upload, path, details, card, bopt, bdet, crpt, next, showSpinner, wall_add, welcome, dep, errorMessage, showCallBack2, showCallBack, confirmTransaction, confirMsg, loaded, transactions, page, currency, head, message, currencies, profile, account, accounts, account2, accounts2, amount} = this.state;
        transactions = transactions.filter((t) => { return t.type.toLowerCase() == page.toLowerCase()});
        let altAcc = "";

        const paymetMob = () => {
            return page == "transfer" ? null : (
                <div className="form-group mt8 imobile" style={{width: "100%", flexDirection: "column"}}>
                    <label>Payment Method</label>
                    <select className="form-control" onChange={(e) => {
                        this.setState({paymet: e.target.value});
                    }} defaultValue={paymet}>
                    {
                        [["transfer", "Bank transfer"], ["crypto", "Crypto wallet"], ["credit", "Credit card"], ["plink", "Payment link"]].map((pyt, pyk) => {
                            let tm = pyt[0];
                            return (tm == "transfer" && bopt["transfer"] < 1) || (tm == "crypto" && bopt["crypto"] < 1) || (tm == "credit" && bopt["credit"] < 1) || (tm == "plink" && bopt["plink"] < 1) ? null : (
                                <option key={"pyk-"+pyk} value={pyt[0]}>{pyt[1]}</option>
                            )
                        })
                    }
                    </select>
                    {welcome ? <img src={ad} alt="" className="ad" /> : null}
                </div>
            );
        }

        const NextPop = () => {
            let your = page == "withdraw" ? "Your " : "";
            return (
                <div className={welcome ? "full" : "overlay fst"}>
                    <div className={"s-reg w2"+(welcome ? " noab" : "")}>
                        {paymetMob()}
                        {paymet == "crypto" ?
                            <form className="blok cl" onSubmit={(e) => {
                                e.preventDefault();
                                let det = {};
                                det.crypto      = $("#paymet-drop").val();
                                det.address     = $("#selected_wall_add").val();
                                this.setState({details: det});
                                this.handleSubmit(true);
                            }}>
                                {welcome ? null : <h4>{app.l("Crypto wallet")}<img src={cancel} className="cancel" onClick={() => this.setState({next: false})} /></h4>}
                                
                                {welcome ?
                                <div className="form-group mt8">
                                    <label>{app.l("Amount")}</label>
                                    <input type="text" className="form-control ft14" placeholder="" name="amount" value={amount} onChange={this.setAmount} />
                                </div> : null}
                                
                                <div className="form-group">
                                    <label>{app.l("Preferred Crypto")}</label>
                                    <select id="paymet-drop" className="form-control" style={{background: "transparent"}}
                                        onChange={(e) => {
                                            this.setState({wall_add: crpt[e.target.value]["wallet"]});
                                        }}
                                    >
                                        {
                                            crpt.map((byp, byk) => {
                                                return (
                                                    <option key={"byk-"+byk} value={page == "withdraw" ? byp.crypto : byk}>{byp.crypto}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <img src={ad} alt="" className="ad" />
                                </div>

                                <div className="form-group" style={{marginBottom: "0px"}}>
                                    <label>{app.l("Wallet Address")}</label>
                                    <input type="text" id="selected_wall_add" style={{fontSize: "14px", textAlign: "left"}} className="form-control" placeholder="" Value={page == "withdraw" ? "" : wall_add} readOnly={page != "withdraw"} required />
                                    {page == "withdraw" ? null : <img src={copy} className="copy" onClick={() => this.copy("selected_wall_add")} />}
                                </div>

                                {page == "withdraw" ? null : <p className="uop">{app.l("Upload Proof of Payment")}</p>}

                                {page == "withdraw" ? null : <div className="form-group fupdl" style={{marginBottom: welcome ? "0px" : "10px"}}>
                                    <FileUploader handleChange={this.fileChange} name="file" types={["JPG", "JPEG", "PNG", "GIF"]} uploadedLabel={app.l("Uploaded Successfully! Upload another?")} />
                                </div>}

                                <button
                                    className="btn btn-warning btn-lg btn-block app-btn next contid bo"
                                    disabled={(page == "withdraw" ? false : path.trim().length == 0) || (welcome ? (amount < this.props.amount) : amount <= 0)}
                                    type={page == "withdraw" ? "submit" : "button"}
                                    style={{top: page == "withdraw" ? "1em" : "0", position: "relative"}}
                                    onClick={() => {
                                    if(path.length && page == "deposit") {
                                        if(welcome) {
                                            this.handleSubmit(true);
                                        } else {
                                            $("#secmit").trigger("click");
                                        }
                                    }
                                }}>{app.l(page.ucwords())}</button>
                                {/* <span className="clf" onClick={() => this.setState({next: false})}>Close</span> */}
                            </form>
                        : paymet == "transfer" ?
                            <form className="blok cl" onSubmit={(e) => {
                                e.preventDefault();
                                let det = {};
                                det.acc_name    = $("#acc_name").val();
                                det.bank_name   = $("#bank_name").val();
                                det.acc_no      = $("#app_acc_no").val();
                                det.iban        = $("#iban").val();
                                det.swift       = $("#swift").val();
                                this.setState({details: det});
                                this.handleSubmit(true);
                            }}>
                                {welcome ? null : <h4>{app.l(page == "withdraw" ? "Your bank details" : "Bank Transfer").toUpperCase()}<img src={cancel} className="cancel" onClick={() => this.setState({next: false})} /></h4>}

                                {welcome ?
                                <div className="form-group mt8">
                                    <label>{app.l("Amount")}</label>
                                    <input type="text" className="form-control ft14" placeholder="" name="amount" value={amount} onChange={this.setAmount} />
                                </div> : null}

                                <div className="form-group mt8">
                                    <label>{app.l("Account Name")}</label>
                                    <input type="text" className="form-control ft14" placeholder="" id="acc_name" Value={page == "withdraw" ? "" : bdet.acc_name} readOnly={page != "withdraw"} required />
                                </div>
                                
                                <div className="form-group mt8">
                                    <label>{app.l("Bank Name")}</label>
                                    <input type="text" className="form-control ft14" placeholder="" id="bank_name" Value={page == "withdraw" ? "" : bdet.bank_name} readOnly={page != "withdraw"} required />
                                </div>
                                
                                <div className="form-group mt8">
                                    <label>{app.l("Account Number")}</label>
                                    <input type="text" id="app_acc_no" className="form-control ft14" placeholder="" Value={page == "withdraw" ? "" : bdet.acc_no} readOnly={page != "withdraw"} required />
                                    {page == "withdraw" ? null : <img src={copy} className="copy" onClick={() => this.copy("app_acc_no")} />}
                                </div>
                                
                                <div className="row" style={{marginBottom: "0px"}}>
                                <div className="col-6 nopad">
                                <div className="form-group">
                                    <label>{app.l("Bank IBAN")}</label>
                                    <input type="text" className="form-control ft14" placeholder="" id="iban" Value={page == "withdraw" ? "" : bdet.iban} readOnly={page != "withdraw"} />
                                </div>
                                </div>
                                
                                <div className="col-6 nopad">
                                <div className="form-group">
                                    <label>{app.l("Bank Swift Code")}</label>
                                    <input type="text" className="form-control ft14" placeholder="" id="swift" Value={page == "withdraw" ? "" : bdet.swift} readOnly={page != "withdraw"} />
                                </div>
                                </div>
                                </div>
                                
                                {page == "withdraw" ? null : <p className="uop">{app.l("Upload Proof of Payment")}</p>}

                                {page == "withdraw" ? null : <div className="form-group fupdl" style={{marginBottom: welcome ? "0px" : "10px"}}>
                                    <FileUploader handleChange={this.fileChange} name="file" types={["JPG", "JPEG", "PNG", "GIF"]} uploadedLabel={app.l("Uploaded Successfully! Upload another?")} />
                                </div>}

                                <button
                                    className="btn btn-warning btn-lg btn-block app-btn next contid bo"
                                    disabled={(page == "withdraw" ? false : path.trim().length == 0) || (welcome ? (amount < this.props.amount) : amount <= 0)}
                                    type={page == "withdraw" ? "submit" : "button"}
                                    style={{top: page == "withdraw" ? "1em" : "0", position: "relative"}}
                                    onClick={() => {
                                    if(path.length && page == "deposit") {
                                        if(welcome) {
                                            this.handleSubmit(true);
                                        } else {
                                            $("#secmit").trigger("click");
                                        }
                                    }
                                }}>{page.ucwords()}</button>
                                {/* <span className="clf" onClick={() => this.setState({next: false})}>Close</span> */}
                            </form>
                        :   <form className="blok cl">
                                {welcome ? null : <h4>{app.l("Credit card")}<img src={cancel} className="cancel" onClick={() => this.setState({next: false})} /></h4>}

                                {profile.cards && profile.cards.length && !welcome ?
                                <div className="form-group mt8">
                                    <label>Cards</label>
                                    <select className="form-control" style={{background: "#141517"}} onChange={(e) => {
                                        this.aufill(profile.cards[e.target.value]);
                                    }}>
                                        {profile.cards.length ? <option value={null}>-- Select Card --</option> : null}
                                        {profile.cards.map((pc, pk) => {
                                            return (
                                                <option value={pk}>{pc.card_name}</option>
                                            )
                                        })}
                                    </select><img src={ad} alt="" className="ad" />
                                </div> : null}

                                <small className='smol' title='For maestro card contact support'>&#9432; &nbsp; For maestro card contact support</small>

                                <CreditCard submit={(card) => {
                                    this.setState({card: card});
                                    if(welcome) {
                                        this.handleSubmit(true);
                                    } else {
                                        if(page == "withdraw") {
                                            this.setState({details: card});
                                            this.handleSubmit(true);
                                        } else {
                                            $("#secmit").trigger("click");
                                        }
                                    }
                                }} />

                                <small className='smol err' style={{color: "#D14", display: "none"}}></small>
                                
                                <div className="row acc-ft">
                                    <div className="col-1"></div>
                                    <div className="col-4">
                                        <div className="form-group fga">
                                            <label>{app.l("Amount")}</label>
                                            {welcome ?
                                            <input type="number" className="form-control" placeholder="" name="amount" value={amount} onChange={(e) => this.setAmount(e, this.props.amount)} /> :
                                            <input type="number" className="form-control" placeholder="" value={amount} readOnly />}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-warning btn-lg btn-block app-btn contid" id="ccpbt" type="button" disabled={welcome ? (amount < this.props.amount) : false} onClick={() => {
                                            $("#submit_card_details").trigger("click");
                                        }}>{app.l(page.ucwords())}</button>
                                    </div>
                                </div>
                                {/* {welcome ? null : <span className="clf" onClick={() => this.setState({next: false})}>Close</span>} */}
                            </form>
                        }
                    </div>
                </div>
            )
        }

        return this.Container(
            <>
                {next ? NextPop() : null}
                <CallBack show={showCallBack} cancel={(e) => this.setState({showCallBack: false})} head={head} text={message} />
                {showCallBack2 ? <MsgAlert cancel={(e) => this.setState({showCallBack2: false})} message={message} /> : null}
                <ConfirmModal
                    head={app.l(head)}
                    text={app.l(confirMsg)}
                    show={confirmTransaction}
                    cancel={() => this.setState({confirmTransaction: false})}
                    confirm={() => { this.setState({confirmTransaction: false}); this.handleSubmit(false); }}
                />
                <Spinner showSpinner={showSpinner} />
                {/* {paymet == "plink" ? <div className='ifr-pop-up' onClick={(e) => {
                    if($(e.target).hasClass("ifr-pop-up")) {
                        this.setState({paymet: "crypto"});
                    }
                }}><iframe src={app.data("plink")} title=""></iframe></div>
                : null} */}
                {welcome ? null :
                <div className="page-title">
                    <h3 className="h-title">{app.l("Transactions")}</h3>
                </div>}

                {welcome ? null :
                <div className="apcc imobile trn">
                    <select className="App-trds trn" style={{cursor: "pointer"}} onChange={(e) => {
                        this.setState({page: e.target.value, currency: app.currency(true), amount: ''});
                    }} defaultValue={page}>
                    {
                        ["Deposit Funds", "Transfer Funds", "Withdraw Funds"].map((typ, tyk) => {
                            return (
                                <option key={"tyk-"+tyk} value={typ.split(" ")[0].toLowerCase()}>{app.l(typ)}</option>
                            )
                        })
                    }
                    </select>
                </div>}

                <div className={"transaction-page "+page}>
                    {welcome ? null :
                        <div className="rw row-1 not-imobile">
                            <ul>
                                <li onClick={() => this.setState({page: "deposit", currency: app.currency(true), amount: ''})}><span className={page == "deposit" ? "active" : null}>{app.l("Deposit Funds")}</span></li>
                                <li onClick={() => this.setState({page: "transfer", currency: app.currency(true), amount: ''})}><span className={page == "transfer" ? "active" : null}>{app.l("Transfer Funds")}</span></li>
                                <li onClick={() => this.setState({page: "withdraw", currency: app.currency(true), amount: '', account: this.state.firstLive})}><span className={page == "withdraw" ? "active" : null}>{app.l("Withdraw Funds")}</span></li>
                            </ul>
                        </div>
                    }

                    {page == "deposit" || page == "withdraw" ?
                    <div className="rw row-2 not-imobile">
                        <ul>
                            <li className="mp"><span>{app.l("Payment Method")}</span></li>
                            {bopt["transfer"] > 0 ? <li className={"mc"+(paymet == "transfer" ? " active" : "")} onClick={() => this.setState({paymet: "transfer"})}><input type="radio" name="paymet" checked={paymet == "transfer"} /> <img src={pmt1} /> <div><span>{app.l("Bank transfer")}</span><span className="lite">{app.l("3-5 Days")}</span></div></li> : null}
                            {bopt["crypto"] > 0 ? <li className={"mc"+(paymet == "crypto" ? " active" : "")} onClick={() => this.setState({paymet: "crypto"})}><input type="radio" name="paymet" checked={paymet == "crypto"} /> <img src={pmt2} /> <div><span>{app.l("Crypto wallet")}</span><span className="lite">{app.l("Instant")}</span></div></li> : null}
                            {bopt["credit"] > 0 ? <li className={"mc"+(paymet == "credit" ? " active" : "")} onClick={() => this.setState({paymet: "credit"})}><input type="radio" name="paymet" checked={paymet == "credit"} /> <img src={pmt3} /> <div><span>{app.l("Credit card")}</span><span className="lite">{app.l("Instant")}</span></div></li> : null}
                            {bopt["plink"] > 0 ? <li className={"mc"+(paymet == "link" ? " active" : "")} onClick={() => {
                                // this.setState({paymet: "plink"});
                                window.open(app.data("plink"), '_blank').focus();
                            }}><input type="radio" name="paymet" checked={paymet == "plink"} /> <img src={plink} /> <div><span>{app.l("Payment link")}</span><span className="lite">{app.l("Custom")}</span></div></li> : null}
                        </ul>
                    </div> : null}
                    <div className={"rw row-3"+(welcome ? "" : " orig")}>
                        {welcome ? NextPop() :
                        <form align="left" className="dform" onSubmit={(e) => { e.preventDefault(); return this.handleSubmit(true); }}>
                            {paymetMob()}
                            <div className="form-group">
                                <label>{page == "transfer" ? "TRANFER FROM" : "SELECT ACCOUNT"}<span className={"att "+(page == "deposit" ? account.split("-")[0].toLowerCase() : "live")}>{page == "deposit" ? account.split("-")[0] : "LIVE"}</span></label>
                                <select id="account_from" className="form-control" value={account} onChange={this.setAccount} required>
                                    {accounts.map(({account_name, account_type, currency, account_label, balance, real_balance}) => {
                                        let cond = page == "deposit" ? true : account_type.toLowerCase() == "live";
                                        if(cond) {
                                            altAcc = altAcc.length ? altAcc : account_name;
                                        }
                                        return cond ? (
                                            <option key={`${Math.random() * 1000000}`} value={account_name}>{(account_label.length ? account_label : account_name.ucwords())+" ("+app.symbol(currency)+app.coma(app.real(balance, 0))+")"}</option>
                                        ) : null}
                                    )}
                                </select>
                                {/* {profile.account_number.length && page != "deposit" ? <span className="grl">{profile.account_number}&nbsp;&nbsp;-</span> : null} */}
                            </div>
                            {page == "transfer" ? 
                            <div className="form-group">
                                <label>TRANSFER TO<span className={"att live"}>LIVE</span></label>
                                <select id="account_to" className="form-control" value={account2} onChange={this.setAccount2} required>
                                    {accounts2.length ? accounts2.map(({account_name, account_label, currency, balance, real_balance}) => (
                                        <option key={`${Math.random() * 1000000}`} value={account_name}>{(account_label.length ? account_label : account_name.ucwords())+" ("+app.symbol(currency)+app.coma(app.real(balance, 0))+")"}</option>
                                    )) : accounts.map(({account_name, account_type, currency, account_label, balance, real_balance}) => {
                                        let cond = account_type.toLowerCase() == "live" && account_name != altAcc;
                                    return cond ? (
                                            <option key={`${Math.random() * 1000000}`} value={account_name}>{(account_label.length ? account_label : account_name.ucwords())+" ("+app.symbol(currency)+app.coma(app.real(balance, 0))+")"}</option>
                                        ) : null}
                                    )}
                                </select>
                                {/* {profile.account_number.length ? <span className="grl">{profile.account_number}&nbsp;&nbsp;-</span> : null} */}
                            </div> : null}

                            {paymet == "plink" ? null : <>
                            <div className="form-group">
                                <label>AMOUNT</label>
                                <input type="text" className="form-control" placeholder="0.00" value={amount > 0 || amount == 0 ? amount : ''} onChange={this.setAmount} required />
                                <span className="grl">
                                    {
                                        page == "deposit" ?
                                        <select className="fc" value={currency} onChange={this.currencySelect}>
                                            {currencies.map((currency) => (
                                                <option key={`${Math.random()}-${Math.random()}`}>
                                                    {currency}
                                                </option>
                                            ))}
                                        </select> : currency
                                    }
                                </span>
                            </div>
                            <div className="form-group aamt">
                                <button type="button" className="btn auto-amt _1" onClick={() => this.setState({amount: 250})}>{app.symbol(currency)} 250</button>
                                <button type="button" className="btn auto-amt _2" onClick={() => this.setState({amount: 500})}>{app.symbol(currency)} 500</button>
                                <button type="button" className="btn auto-amt _3" onClick={() => this.setState({amount: 1000})}>{app.symbol(currency)} 1000</button>
                            </div></>
                        }
                            {/* {page == "withdraw" ? <div className="form-group" style={{marginTop: "1em"}}>
                                <label>{paymet == "crypto" ? "CRYPTO WALLET" : "ACCOUNT NUMBER"}</label>
                                <input type="text" id="sel_account_number" className="form-control" placeholder="" defaultValue={profile.account_number} style={{textAlign: "left"}} />
                            </div> : null} */}

                            {errorMessage ? <p className='error'>{errorMessage}</p> : null}
                            {page == "withdraw" && this.cannotWith(account, amount) ? <p className='error'>You cannot withdraw above your free margin(${this.cannotWith(account, amount, true)})</p> : null}

                            {page == "deposit" && !dep ? <>
                                <div className="form-group" style={{marginTop: "1em"}}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block app-btn nodep">{app.l("Deposit")}</button>
                                    <marquee scrollamount="6" style={{marginTop: "10px"}}><small>{app.l("Currently not available, please contact our support team")}.</small></marquee>
                                </div>
                            </> : (
                                (page == "transfer" && (account2.length && account2.split("-")[0].toLowerCase() == "demo")) ||
                                // (page == "transfer" && (account.split("-")[0].toLowerCase() != account2.split("-")[0].toLowerCase())) || {page.ucwords()}
                                (page == "withdraw" && account.split("-")[0].toLowerCase() == "demo") ||
                                (page == "withdraw" && this.cannotWith(account, amount)) ||
                                (amount <= 0 && paymet != "plink") ?
                                <div className="form-group" style={{marginTop: "1em"}}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block app-btn" style={{opacity: 0.5}}>{app.l(page == "deposit" && account.split("-")[0].toLowerCase() == "live" ? "Next" : page.ucwords())}</button>
                                </div> :
                                <div className="form-group" style={{marginTop: "1em"}}>
                                    {
                                        (page == "deposit" || page == "withdraw") && account.split("-")[0].toLowerCase() == "live" ?
                                        <button type="button" onClick={() => {
                                            if(!welcome && page == "deposit" && paymet == "plink") {
                                                window.open(app.data("plink"), '_blank').focus();
                                                return false;
                                            }
                                            this.setState({next: true});
                                            if(!welcome) {
                                                if(page == "withdraw") {
                                                    if(paymet == "credit") {
                                                        this.aufill();
                                                    } else if(paymet == "transfer") {
                                                        setTimeout(() => {
                                                            $("#acc_name").val(profile.account_name);
                                                            $("#bank_name").val(profile.bank_name);
                                                            $("#app_acc_no").val(profile.account_number);
                                                            $("#iban").val(profile.bank_IBAN);
                                                            $("#swift").val(profile.bank_SWIFT_code);
                                                        }, 0);
                                                    }
                                                }
                                            }
                                        }} className="btn btn-warning btn-lg btn-block app-btn">{app.l("Next")}</button> :
                                        <button type="submit" className="btn btn-warning btn-lg btn-block app-btn">{app.l(page.ucwords())}</button>
                                    }
                                    <button type="submit" id="secmit" style={{display: "none"}}>{app.l("Submit")}</button>
                                </div>
                            )}
                        </form>}
                    </div>

                    {transactions.length && !welcome ?
                    <div className={"trade-history imobile"}>
                        <h3 className="h-title">{app.l((page == "withdraw" ? "Withdrawal" : page).ucwords()+" history")}</h3>
                        <ul className="tmob-hist">
                            {transactions.map((v, k) => {
                                let kid = "cb-"+k;
                                return (
                                    <li key={kid}>
                                        <div>
                                            <span className="coll lft"><small>{app.l("Type")}</small><span>{page.toUpperCase()}</span></span>
                                            <span className="coll rgt"><small>{app.l("Date")}</small><time>{app.cleanDate(v.create_time, 0)}</time></span>
                                        </div>
                                        <div>
                                            <span className="coll lft">
                                                <small>{app.l(page == "deposit" ? "ACCOUNT" : "FROM")}</small>
                                                <span>{page == "deposit" ? v.account_to.ucwords() : v.account_from.ucwords()}</span>
                                            </span>
                                            <span className="coll rgt">
                                                <small>{page == "deposit" || page == "withdraw" ? null : app.l("TO")}</small>
                                                <span>{page == "deposit" || page == "withdraw" ? null : v.account_to.ucwords()}</span>
                                            </span>
                                        </div>
                                        <div>
                                            <span className="coll lft"><small>{app.l("AMOUNT")}</small><b>{v.amount}</b></span>
                                            <span className="coll rgt"><small>{app.l("AMOUNT($)")}</small><b>${app.floatFormat(v.actual.split()[0])}</b></span>
                                        </div>
                                        <div>
                                            <span className="coll lft"><small>{app.l("STATUS")}</small><span>{page != "transfer" ? app.status(v.status) : 'Success'}</span></span>
                                            <span className="coll rgt"><small>{app.l("REFERENCE")}</small><span>{v.reference}</span></span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>: null}

                    {welcome ? null :
                    <div className={"tb row-4"+(page == "deposit" || page == "withdraw" ? "" : " ndp")+" not-imobile"}>
                        <h3 className="h-title">{app.l((page == "withdraw" ? "Withdrawal" : page).ucwords()+" history")}</h3>
                        <div className="table-responsive">
                            <table className={"table table-hover "+page}>
                                <thead>
                                    <tr>
                                        <th>{app.l("DATE")}</th>
                                        <th className="m2">{app.l(page == "deposit" ? "ACCOUNT" : "FROM")}</th>
                                        {page == "deposit" || page == "withdraw" ? null : <th>{app.l("TO")}</th>}
                                        <th>{app.l(page.toUpperCase())}</th>
                                        <th>{app.l("AMOUNT")}</th>
                                        {page != "transfer" ? <th>{app.l("Status")}</th> : null}
                                        <th className="m2">{app.l("REFERENCE NO.")}</th>
                                        <th style={{width: "50px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.map((v, k) => {
                                        let kid = "cb-"+k;
                                        return (
                                            <tr key={kid}>
                                                <td>{app.cleanDate(v.create_time, 0)}</td>
                                                <td className="m2">{page == "deposit" ? v.account_to.ucwords() : v.account_from.ucwords()}</td>
                                                {page == "deposit" || page == "withdraw" ? null : <td>{v.account_to.ucwords()}</td>}
                                                <td>{v.amount}</td>
                                                <td>{app.symbol(v.actual.split(" ")[1])}{app.coma(Number(v.actual.split(" ")[0]).toFixed(2))}</td>
                                                {page != "transfer" ? <td>{app.status(v.status)}</td> : null}
                                                <td className="m2">{v.reference}</td>
                                                <td style={{width: "50px"}}>
                                                    <input type="checkbox" className="kid" id={kid} checked={page == "withdraw" ? v.status == 2 : true}/>
                                                    <label for={kid} className="kid"></label>
                                                </td>
                                            </tr>
                                        )}
                                    )}
                                </tbody>
                            </table>
                            {loaded && !transactions.length ? <TradeNotFound text={"Empty"} /> : null}
                            {loaded || transactions.length ? null : <div className='loader-container' style={{ display : 'block', overflow: "hidden" }}><div className='loader' style={{margin: "30px auto"}}></div></div>}
                        </div>
                    </div>}
                </div>
            </>
        , welcome);
    }
}

export default withRouter(Transactions);