import $ from 'jquery';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
// import logo from '../../Assets/logo.svg';
// import avatar from '../../Assets/avatar.png';
import avatar from '../../themes/images/tradeDashboard/t_nav3.png';
import nt from '../../Assets/notification.svg';
import ch from '../../Assets/chat.svg';
import pencil from '../../Assets/pencil.svg';
import ad from '../../Assets/ad.png';
import ad2 from '../../Assets/ad2.svg';
import ad2Alt from '../../Assets/ad2-alt.svg';
import menu from '../../Assets/menu.svg';
import menuAlt from '../../Assets/menu-alt.svg';
import Spinner from '../../components/spinner/index';

import checked from '../../Assets/checked.svg';
import canx from '../../Assets/x.svg';
import cancel from '../../Assets/cancel.svg';
import rect from '../../Assets/rect.svg';
import Message from './Components/message';
import Notifications from './Components/notifications';
import HoverBox from './Components/hover';
import TransactionPage from '../Pages/Transactions';
import spain from "./32/Spain.png";
import italy from "./32/Italy.png";
import uk from "./32/United-Kingdom.png";
import germany from "./32/Germany.png";
import france from "./32/France.png";

import news from '../../Assets/news.svg';
import newsAlt from '../../Assets/news-alt.svg';
import trade from '../../Assets/trade.svg';
import line from '../../Assets/line.svg';
import tradeAlt from '../../Assets/trade-alt.svg';
import accounts from '../../Assets/accounts.svg';
import accountsAlt from '../../Assets/accounts-alt.svg';
import transactions from '../../Assets/transactions.svg';
import transactionsAlt from '../../Assets/transactions-alt.svg';
import { Logout, CallBack, MsgAlert } from '../../components/popups/index';
import './Index.scss';

import server from '../../services/server';
import socketPlug from '../../services/emit';
import app from '../../services/app';

let logo = app.custom("logo");
let blur_timeout = null;

window.ac_badge = 0;
let dpage = window.location.pathname.replace("/", "").toLowerCase();
let isOnApp = ["book", "trade", "accounts", "profile", "forgotPassword", "changePassword", "news", "transactions"].indexOf(dpage) > -1;
if(isOnApp && !app.isAdmin()) {
  (async () => {
    if(app.loggedIn()) {
      setTimeout(() => { $("#_sphs_").addClass("hide"); }, 100);
      const gp = await server.getProfile();
      app.profile(gp.data.profile);
    } else {
      window.location.href = "/login?_erl";
      process.exit(0);
    }
  })();
} else {
  $("#_sphs_").addClass("hide");
}

class Container extends Component {
    constructor(props) {
        super(props);
        let page = window.location.pathname.replace("/", "").toLowerCase();
        let profile = app.profile();
  
        let acc_types = app.data("atype").split(",");
        let acq = [];
        
        for (let q = 0; q < acc_types.length; q++) {
            let arq = acc_types[q].split(":");
                arq[0] = arq[0].toLowerCase();
                arq[1] = Number(arq[1]);
            acq.push(arq);
        }

        let fcq = acq.filter((fq) => fq[0] == profile.acc_type.toLowerCase());

        if(fcq.length) {
            fcq = fcq[0];
        } else {
            fcq = acq[0];
        }

        this.state = {
            page,
            profile,
            welcome: Number(profile["welcome"]) > 0 ? 0 : 1,
            // welcome: 1,
            no_live_deposit: !(profile.live_deposit > 0),
            first_log: !(profile.first_log > 0),
            hover: false,
            mbox: false,
            ibox: false,
            wor: true,
            settings: false,
            callouts: false,
            newNotice: 0,
            newMessage: 0,
            acc_type:   fcq[0],
            acc_price:  fcq[1],
            acc_types:  acq,
            account: app.account(),
            showLogout: false,
            showMenu: false,
            idle: false,
            showSpinner: false,
            contCallback: false,

            city: '',
            state: '',
            zipcode: '',
            trade_history: 'Traded on stocks',
            occupation: 'Retired / pensioner',
            politics: 'Yes',
            us_citizen: 'Yes',
            interest: 'Automatic trading software',

            questionnaire: [],
            survey: [],
            pdet: []
        }

        this.aryIannaTimeZones = Intl.supportedValuesOf ? Intl.supportedValuesOf('timeZone') : [];
        this.audio = new Audio(require("../../themes/sounds/new.ogg").default);
        this.eudio = new Audio(require("../../themes/sounds/eudio.mp3").default);
        this.notify = new Audio(require("../../themes/sounds/note.ogg").default);
    }

    uflog = async (to = false) => {
        this.setState({first_log: !this.state.first_log});
        if(to) {
            this.props.history.push("/Transactions");
        }
        await server.uflog();
        let getProfile  = await server.getProfile();
        let profile     = getProfile.data.profile;
        app.profile(profile);
    }

    uWel = async () => {
        let { pdet, survey } = this.state;
        this.setState({showSpinner: true});
        if(survey.length) {
            pdet["survey"] = JSON.stringify(survey);
        }
        await server.uWel(Object.assign({}, pdet));
        this.setState({contCallback: true});
        let getProfile  = await server.getProfile();
        let profile     = getProfile.data.profile;
        app.profile(profile);
        this.setState({welcome: 0, showSpinner: false});
    }

    ring = (n) => {
        n = this.state.newMessage + Number(n);
        this.setState({newMessage: n});
        if(n > 0) {
            $(this.audio)[0].play();
        }
    }

    ring2 = (n) => {
        n = Number(n);
        this.setState({newNotice: n});
        if(n > 0) {
            $(this.notify)[0].play();
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          [name]: value.trim(),
        });
    }

    onShow = () => {
        $(window).trigger("onShow");
        setTimeout(() => {
            let element = document.getElementById("messageList");
            element.scrollTop = element.scrollHeight - element.clientHeight;
            window.scrollChat = true;
        }, 250);
    }

    socketInit = async () => {
        if(window.WebSocketPlugged) {
            window.WebSocketPlug.addEventListener('message', async ({data}) => {
                try {
                    let message = JSON.parse(`${data}`);
                    let payload = message.payload;
                    if(message.event == "AUTO_REFRESH" && !app.isAdmin()) {
                        const gp = await server.getProfile();
                        app.profile(gp.data.profile);
                        window.location.href = "";
                        // window.location.href = app.isVerified() ? "" : "/Profile";
                    }
                } catch (e) {
                    return e;
                }
            });
        }
    }

    btimeout = () => {
        this.setState({idle: true});
        $(this.eudio)[0].play();
        setTimeout(() => {
            if(this.state.idle) {
                window.location.href = "/Login?_ts="+app.profile()["email"];
            }
        }, 1000 * 60);
    }

    async componentDidMount () {
        if(this.state.welcome > 0) {
            this.setState({showSpinner: true});
            this.getQr();
        }

        if(!app.isAdmin()) {
            socketPlug();
        }

        $(window).on("blur", () => {
            blur_timeout = setTimeout(this.btimeout, 1000 * 60 * 15);
        });

        $(window).on("focus", () => {
            clearTimeout(blur_timeout);
        });

        $("title").html(app.custom("name")+" - "+this.state.page.toUpperCase());
        if($(".devfx-splash-screen").length) $(".devfx-splash-screen").fadeOut(1500);
        $(window).on("renewSocket", () => {
            this.socketInit();
        });
        $(window).on("toggleSettings", () => {
            if(!this.state.settings) {
                this.setState({settings: true});
            }
        });
        if(window.WebSocketPlugged) {
          $(window).trigger("renewSocket");
        }
        $(document).on("click", (e) => {
          if(!$(e.target).hasClass("myd") && $("#myDropdown").hasClass("show")) {
            $("#myDropdown").removeClass("show");
          }
        });
        
        let call = null;
        if(this.props.history.location.search.indexOf("?call") > -1) {
            this.setState({callouts: true});
        }
    }

    logout = async () => {
      try {
        let vs = localStorage.getItem("avariz_vs") || 0;
        let cs = localStorage.getItem("avariz_cs") || 0;
        localStorage.clear();
        if(vs) {localStorage.setItem("avariz_vs", vs);}
        if(cs) {localStorage.setItem("avariz_cs", cs);}
        // this.props.history.push('/Login?logout');
        window.location.href = '/Login';
      } catch (error) {
        console.log(">>>", error);
      }
    }

    getQr = async () => {
      let questionnaire = app.questions();
      try {
        let qr = await server.getQr();
        let qs = qr.data.qs;
        this.setState({questionnaire: qs.length ? qs : questionnaire, showSpinner: false});
      } catch(e) {
        this.setState({showSpinner: false});
        return e;
      }
    }

    setTz = async (utz) => {
        let dtz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.setState({showSpinner: true});
        try {
          let tz = await server.uTz({dtz, utz});
          let pr = app.profile();
          pr["dtz"] = dtz;
          pr["utz"] = utz;
          app.profile(pr);
          this.setState({settings: false, showSpinner: false});
        } catch(e) {
          this.setState({settings: true, showSpinner: false});
          return e;
        }
      }

    _next = (w, names, sv = false) => {
        let { survey, pdet, questionnaire } = this.state;
        names.forEach((n, k) => {
            if(sv) {
                survey[w - 3] = [questionnaire[n]["question"], $("[name=survey_"+n+"]").val()];
            } else {
                pdet[n] = $("[name="+n+"]").val();
            }
        });
        this.setState({welcome: w+1, survey, pdet});
    }
    
    tgs = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    render () {
        let { page, wor, settings, callouts, showMenu, contCallback, profile, showSpinner, survey, questionnaire, no_live_deposit, first_log, acc_type, acc_types, acc_price, welcome, idle } = this.state;

        let lacc        = "";
        let pg          = window.location.pathname.replace("/", "").toLowerCase();

        let deposcreen  = welcome > (questionnaire.length + 2);
        let questscreen = welcome > 2 && welcome <= (questionnaire.length + 2);

        let renBar = (c) => {
            return welcome > 1 ?
            <div class={"pbar-w "+c}>
                <span>{app.l("Step "+(welcome == 2 ? 1 : questscreen ? 2 : 3)+"/3")}</span>
                <div class="bar top" style={{width: "calc((100% / 3) * "+(welcome == 2 ? 1 : questscreen ? 2 : 3)+")"}}></div>
            </div> : null
        }

        return app.isAdmin() ? (
            <div className="App" id="devfx-app">
                <div className="App-body inside adm" style={{marginTop: "0px"}}>
                    <div className={"container "+page} {...this.props} style={{width: "100%"}}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        ) : (
            <div className="App" id="devfx-app">
                <Logout show={this.state.showLogout} cancel={(e) => this.setState({showLogout: false})} confirm={this.logout} />
                <Spinner showSpinner={showSpinner} />
                <CallBack show={contCallback} cancel={(e) => this.setState({contCallback: false})} head={app.l("Response saved!")} text={app.l("Thank you.")} />
                {idle ? <div className='are-you-still-logged-in'>
                    <div><span>{app.l("Are you still logged in?")}</span><button onClick={() => {
                        this.setState({idle: false});
                    }}>{app.l("Yes")}</button></div>
                </div> : null}
                {showMenu ?
                <div id="app-menu-overlay" onClick={() => this.setState({showMenu: false})}>
                    <div id="app-menu">
                        <a href={app.data("home")} target="_blank"><img className="App-logo imobile" src={logo} alt="" /></a>
                        <img className="csl imobile" src={cancel} onClick={() => this.setState({showMenu: false})} />
                        <Link to="/Trade" onClick={() => this.setState({showMenu: false})} className={"ml"+(page == "trade" ? " active": "")}><img src={page == "trade" ? trade : trade} /><h4>{app.l("Trade")}</h4><span>{app.l("Trade on all financial assets")}</span></Link>
                        <Link to="/Accounts" onClick={() => this.setState({showMenu: false})}  className={"ml"+(page == "accounts" ? " active": "")}><img src={page == "accounts" ? accounts : accounts} /><h4>{app.l("Trading Accounts")}</h4><span>{app.l("View & open Trading accounts")}</span></Link>
                        <Link to="/News" onClick={() => this.setState({showMenu: false})}  className={"ml"+(page == "news" ? " active": "")}><img src={page == "news" ? news : news} /><h4>{app.l("Financial News")}</h4><span>{app.l("Get up-to-date news")}</span></Link>
                        <Link to="/Transactions" onClick={() => this.setState({showMenu: false})}  className={"ml"+(page == "transactions" ? " active": "")}><img src={page == "transactions" ? transactions : transactions} /><h4>{app.l("Transactions")}</h4><span>{app.l("Deposit, Transfer & Withdraw")}</span></Link>
                    </div>
                </div> : null}
                <header className={welcome || idle ? "blur-ct" : ""}>
                    <div className="a-left">
                        <a href={app.data("home")} target="_blank"><img className="App-logo" src={logo} alt="" /></a>
                        <select className="App-accs not-imobile" style={{cursor: "pointer", border: 0}} onChange={(e) => {
                            $(".App-accs").css({opacity: 0.3});
                            app.account(e.target.value);
                            this.setState({account: e.target.value});
                        }} defaultValue={app.account()}>
                            {
                                profile.accounts.map((acc, ack) => {
                                    if(acc.account_type.toLowerCase() == "live" && !lacc.length) {
                                        lacc = acc.account_name;
                                    }
                                    let _lab = acc.account_name.ucwords().split("-").join(" - ");
                                    return (
                                        <option key={"ack-"+ack} value={acc.account_name}>{acc.account_label.length ? acc.account_label : _lab}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="a-right">
                        {
                            page.toLowerCase() == "transactions" ? null : <>
                                <Link to="/Transactions?t=withdraw" className="trans with">{app.l("Withdraw")}</Link>
                                <Link to="/Transactions?t=deposit" className="trans dep" style={{marginRight: "100px"}}>{app.l("Deposit")}</Link>
                            </>
                        }
                        {/* <div class="dropdown">
                            <button onClick={() => this.tgs()} className="dropbtn myd">
                                <span className="myd"><img className="myd" src={uk} alt="" /><img src={ad2} className="ad3" /></span>
                            </button>
                            <div id="myDropdown" class="dropdown-content">
                                <span><img src={spain} alt="" /></span>
                                <span><img src={italy} alt="" /></span>
                                <span><img src={uk} alt="" /></span>
                                <span><img src={germany} alt="" /></span>
                                <span><img src={france} alt="" /></span>
                            </div>
                        </div> */}
                        <div className="menu"
                            // onMouseEnter={() => this.setState({showMenu: true, mbox: false, ibox: false, hover: false})}
                            onClick={() => this.setState({showMenu: !showMenu, mbox: false, ibox: false, hover: false})}>
                            <span style={{color: showMenu ? 'var(--custom_color)' : '#fff'}}>{app.l("Menu")}</span>
                            <img src={showMenu ? menu : menu} className="meni" />
                            <img src={showMenu ? ad2 : ad2} className="ad2" />
                        </div>
                        <div className={'live-chat-mbox'+(this.state.mbox ? ' mbox' : '')} style={{position: "relative", cursor: "pointer"}}>
                            <img
                                alt=''
                                src={ch}
                                className="ch"
                                // onMouseEnter={() => { this.setState({showMenu: false, mbox: true, ibox: false, hover: false, newMessage: 0}); this.onShow(); }}
                                onClick={() => { this.setState({showMenu: false, mbox: !this.state.mbox, ibox: false, hover: false, newMessage: 0}); this.onShow(); }} />
                            {this.state.newMessage > 0 ? <span className="__newMessage">{this.state.newMessage}</span> : null}
                            {this.state.mbox && (
                                <div className='overlay drop' onClick={() => this.setState({showMenu: false, mbox: false, hover: false, ibox: false})}></div>
                            )}
                            <Message
                                name={(profile.first_name+" "+profile.last_name).ucwords()}
                                show={this.state.mbox}
                                ring={(n) => this.ring(n)}
                            />
                        </div>
                        <div className={'live-notice-ibox'+(this.state.ibox ? ' ibox' : '')} style={{position: "relative", cursor: "pointer"}}>
                            <img
                                src={nt}
                                className="nt"
                                alt=''
                                // onMouseEnter={() => { this.setState({showMenu: false, mbox: false, ibox: true, hover: false, newNotice: 0}); }}
                                onClick={() => { this.setState({showMenu: false, mbox: false, ibox: !this.state.ibox, hover: false, newNotice: 0}); }}
                            />
                            {this.state.newNotice > 0 ? <span className="__newNotice"></span> : null}
                            {this.state.ibox && (
                                <div className='overlay drop' onClick={() => this.setState({showMenu: false, mbox: false, hover: false, ibox: false})}></div>
                            )}
                            <Notifications
                                name={(profile.first_name+" "+profile.last_name).ucwords()}
                                show={this.state.ibox}
                                ring={(n) => this.ring2(n)}
                            />
                        </div>

                        <div
                            className={'tpr live-hover-box'+(this.state.hover ? ' box' : '')}
                            style={{position: "relative", cursor: "pointer", minWidth: "170px"}}
                            // onMouseEnter={() => this.setState({showMenu: false, mbox: false, ibox: false, hover: true})}
                            >
                            <img src={profile.profile_image.length ? profile.profile_image+"?t="+profile.modify_time : avatar} className="dp" alt=''
                                onClick={() => this.setState({showMenu: false, mbox: false, ibox: false, hover: !this.state.hover})}
                            />
                            <span className="u-name"
                                onClick={() => this.setState({showMenu: false, mbox: false, ibox: false, hover: !this.state.hover})}
                            >{profile.first_name.ucwords()}</span>
                            {this.state.hover && (
                                <div className='overlay drop' onClick={() => this.setState({showMenu: false, mbox: false, hover: false, ibox: false})}></div>
                            )}
                            <HoverBox
                                name={(profile.first_name+" "+profile.last_name).ucwords()}
                                email={profile.email}
                                src={profile.profile_image.length ? profile.profile_image+"?t="+profile.modify_time : avatar}
                                balance={app.real(app.accountDetail().balance, app.accountDetail().real_balance)}
                                logout={() => {
                                    this.setState({mbox: false, hover: false, ibox: false, showLogout: true})
                                }}
                            />
                            <img src={ad} className="ad not-imobile"
                                onClick={() => this.setState({showMenu: false, mbox: false, ibox: false, hover: !this.state.hover})}
                            />
                            <img src={line} className="ad imobile"
                                onClick={() => this.setState({showMenu: !showMenu, mbox: false, ibox: false, hover: false})}
                            />
                        </div>
                    </div>
                </header>

                {callouts ?
                    <div className="overlay fst wel">
                        <ul class="callouts">
                            <li class="callouts--right">
                                <span>Lorem Ipsum</span>
                                <span className='close-call' onClick={() => this.setState({callouts: false})}>GOT IT</span>
                            </li>
                            {/* <li class="callouts--top">Lorem</li>
                            <li class="callouts--bottom">Lorem</li>
                            <li class="callouts--left">Lorem</li> */}
                        </ul>
                    </div>
                : null}
                
                {settings ? <div className="overlay fst wel forset" style={{zIndex: "100000000"}} onClick={(e) => {
                    if($(e.target).hasClass("forset")) {
                        this.setState({settings: false});
                    }
                }}>
                    <div className="s-reg w2">
                        <div className="blok cl">
                            <h4>{app.l("SETTINGS")}</h4>
                            <img className="csl" src={cancel} onClick={(e) => this.setState({settings: false})} style={{top: "15px"}} />
                            <div className="iblk">
                                <ul>
                                    <li><b>{app.l("SOFTWARE VERSION")}</b> <input type="text" className="editable" defaultValue={app.version()} readOnly /></li>
                                    <li><b>{app.l("User ID")}</b> <input type="text" className="editable" defaultValue={app.uid(profile.user_id)} readOnly /></li>
                                    <li><b>{app.l("CURRENT ACCOUNT")}</b> <input type="text" className="editable" defaultValue={app.accountDetail()["account_name"].ucwords()} readOnly /></li>
                                    <li><b>{app.l("ACCOUNT TYPE")}</b> <input type="text" className="editable" defaultValue={app.accountDetail()["atype"].toUpperCase()} readOnly /></li>
                                    <li><b>{app.l("LAST LOGIN")}</b> <input type="text" className="editable" defaultValue={'-'} readOnly /></li>
                                    <li><b>{app.l("CURRENT TIMEZONE")}</b> <input type="text" className="editable" defaultValue={app.profile()["utz"] || Intl.DateTimeFormat().resolvedOptions().timeZone} readOnly /></li>
                                </ul>
                            </div>
                            <h5 style={{fontSize: "22px", width: "100%", textAlign: "left", paddingLeft: "16px", marginTop: "10px"}}>{app.l("Update timezone")}</h5>
                            <div className="form-group">
                                <select className="form-control" id="u_tz_sel" key={"u_tz_sel"} defaultValue={app.profile()["utz"] || ""}>
                                    {this.aryIannaTimeZones.map((timeZone) => {
                                        let date = new Date;
                                        // let strTime = date.toLocaleString("en-US", {timeZone: `${timeZone}`});
                                        let _tz = moment(date).tz(timeZone).format("Z");
                                        return (<option key={"st-"+timeZone} value={timeZone}>(GMT{_tz}) {timeZone}</option>)
                                    })}
                                </select>
                                <img src={ad} alt="" className="ad" />
                            </div>
                            <button className="btn btn-warning btn-lg btn-block app-btn" type="button" disabled={showSpinner} onClick={() => {
                                if(!showSpinner) {
                                    this.setTz($("#u_tz_sel").val());
                                }
                            }}>{app.l("Update")}</button>
                        </div>
                    </div>
                </div> : null}

                {welcome && questionnaire.length > 0 ?
                    <div className="overlay fst wel">

                        <div className={"s-reg "+(welcome == 1 ? "w1" : (deposcreen ? "w3" : "w2")+(questscreen ? " que" : ""))}>
                        {renBar("not-imobile")}
                        {welcome == 1 ? 
                            <div className="blok cl">
                                <h1>{app.l("Let's Help You Personalize Your Trading Account")}</h1>
                                <h4 style={{fontWeight: "100"}}>{app.l("Please set aside 2 minutes to complete this process!")}</h4>
                                <button className="btn btn-warning btn-lg btn-block app-btn next" type="button" style={{width: "80%"}} onClick={() => {
                                    this.setState({welcome: 2});
                                }}>{app.l("Next")}</button>
                                <div className="row">
                                    <h3 className="bac"><span>{acc_type.ucwords()} Account<br />{app.l("Minimum required deposit")}</span><b>${acc_price}</b></h3>
                                </div>
                            </div>
                        : welcome == 2 ?
                            <div className={"blok cl"}>
                                <h4>{app.l("Confirm your personal information")}</h4>
                                <div className="iblk">
                                    <img src={pencil} alt="" className="pencil" onClick={() => this.setState({wor: !wor})} />
                                    <ul>
                                        <li><b>{app.l("First Name", true)}</b> <input type="text" name="first_name" className="editable" defaultValue={profile.first_name} placeholder="----" readOnly={wor} /></li>
                                        <li><b>{app.l("Last Name", true)}</b> <input type="text" name="last_name" className="editable" defaultValue={profile.last_name} placeholder="----" readOnly={wor} /></li>
                                        <li><b>{app.l("Gender", true)}</b> <input type="text" name="gender" className="editable" defaultValue={profile.gender} placeholder="----" readOnly={wor} /></li>
                                        <li><b>{app.l("Email", true)}</b> <input type="email" name="email" className="editable" defaultValue={profile.email} placeholder="----" readOnly={wor} /></li>
                                        <li><b>{app.l("Phone Number", true)}</b> <input type="text" name="phone_number" className="editable" defaultValue={profile.phone_number} placeholder="----" readOnly={wor} /></li>
                                        <li><b>{app.l("Account Type", true)}</b> <input type="text" name="acc_type" className="editable" defaultValue={profile.acc_type.toUpperCase()} placeholder="----" readOnly={wor} /></li>
                                    </ul>
                                </div>
                                <h5 style={{fontSize: "22px", width: "100%", textAlign: "left", paddingLeft: "16px", marginTop: "10px"}}>{app.l("Where are u located?")}</h5>
                                <div className="form-group">
                                    <label>{app.l("City")}</label>
                                    <input type="text" className="form-control" name="city" defaultValue={this.state.city} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>{app.l("State")}</label>
                                    <input type="text" className="form-control" name="state" defaultValue={this.state.state} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>{app.l("Zip Code")}</label>
                                    <input type="text" className="form-control" name="zipcode" defaultValue={this.state.zipcode} onChange={this.handleInputChange} />
                                </div>
                                <button className="btn btn-warning btn-lg btn-block app-btn next" type="button" onClick={() => {
                                    this._next(welcome, ["city", "state", "zipcode"]);
                                }}>{app.l("Next")}</button>
                            </div>
                        : questscreen ? (
                            questionnaire.map((qv, qk) => {
                                let qd = qk+1;
                                return (welcome-2) == qd ? (
                                    <div className="blok cl" key={"qk-"+qk}>
                                        <h4>{app.l("Tell us more about yourself")}</h4>
                                        <h5 style={{fontSize: "22px", width: "100%"}}><span>{qd}.</span> {app.l(qv.question+"?")}</h5>
                                        <div className="form-group">
                                            <select className="form-control" name={"survey_"+qk} onChange={this.handleInputChange}>
                                                {qv.answers.map((qa, qq) => <option key={"qo-"+qq}>{app.l(qa)}</option>)}
                                            </select>
                                            <img src={ad} alt="" className="ad" />
                                        </div>
                                        <button className="btn btn-warning btn-lg btn-block app-btn next" type="button" onClick={() => {
                                            this._next(welcome, [qk], true);
                                        }}>{app.l("Next")}</button>
                                    </div>
                                ) : null
                            })
                        ) : deposcreen ?
                        <div className="blok cl">
                            <h4 style={{padding: "5px", fontSize: "16px", paddingBottom: "10px"}}>{app.l("Deposit to activate your Live trading account")}</h4>
                            <TransactionPage welcome={true} amount={acc_price > 0 ? Number(acc_price) : 1} done={this.uWel} />
                            <div className="row b" style={{justifyContent: "center", marginBottom: "1em", alignItems: "center"}}>
                                <h3 className="bac"><span style={{minHeight: "54px", display: "inline-flex", alignItems: "center"}}>{acc_type.ucwords()} Account<br />{app.l("Minimum required deposit")}</span><b>${acc_price}</b></h3>
                            </div>
                        </div>
                    : null}
                    {welcome > 1 ?
                    <div class="pbar-x">
                        {welcome > 2 ? <span onClick={() => this.setState({welcome: welcome-1})}>&#60;&#60; {app.l("Previous")}</span> : <span></span>}
                        <span onClick={() => {
                            if(deposcreen) {
                                this.uWel();
                            }
                            this.setState({welcome: deposcreen ? 0 : welcome+1})
                        }}>{app.l(deposcreen ? "Finish" : "Skip")} &#62;&#62;</span>
                    </div> : null}
                    {questscreen ? <div class="pbar-w bottom"><div class="bar" style={{width: "calc((100% / "+(questionnaire.length)+") * "+(welcome - 2)+")"}}></div></div> : null}
                    </div>
                </div> : null}




                {/*
                {first_log && pg == "trade" ?
                <div className="overlay fst">
                    <div className="s-reg">
                        <div className="sr-t">
                            <h2>You have successfully registered</h2>
                            <img className="csl" src={cancel} onClick={(e) => this.uflog()} />
                        </div>
                        <div className="blok">
                            <div className="blk _1">
                                <h5>Credited to your Demo account</h5>
                                <h1>$10,000</h1>
                                <span>No withdrawals</span>
                                <span>Free top-up</span>
                                <button className="app-btn" onClick={(e) => this.uflog()}>
                                    <span>Start trading</span>
                                    <span>On practice account</span>
                                </button>
                            </div>
                            <div className="blk _2">
                                <h5>We have also created a Live account for you</h5>
                                <h1>$0.00</h1>
                                <span>Easy withdrawals</span>
                                <span>Deposit without comission</span>
                                <button className="app-btn" onClick={(e) => this.uflog(true)}>
                                    <span>Top up your account</span>
                                    <span>with real funds</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                
                {!first_log && no_live_deposit && pg == "trade" ?
                    <div className="few-steps">
                        <div className="fh">
                            <div className='loader-container'>
                                <div className='loader'></div>
                            </div>
                            <img src={canx} alt="" className="canx" onClick={() => this.setState({no_live_deposit: !no_live_deposit})} />
                            <button className="j">40%</button>
                            <span>You’re just a few steps to completing your profile</span>
                        </div>
                        <ul>
                            <li><img src={checked} /> <span>Create an account</span></li>
                            <li><img src={checked} /> <span>Select an asset you like</span></li>
                            <li><button className="i">3</button> <span>Your first forecast</span></li>
                            <li><button className="i">4</button> <span>Confirm your Email</span></li>
                            <li><button className="i">5</button> <span>Top up your account</span></li>
                            <li><Link to={"/Transactions?a="+lacc} className="app-btn dep">Deposit</Link></li>
                        </ul>
                    </div> : null}
                */}


                <div className={"App-body inside"+(welcome || idle ? " blur-ct" : "")}>
                    <div className={"container "+page} {...this.props}>
                    {page == "trade" ?
                    <div className="apcc">
                        <select className="App-accs imobile" style={{cursor: "pointer"}} onChange={(e) => {
                            $(".App-accs").css({opacity: 0.3});
                            app.account(e.target.value);
                            this.setState({account: e.target.value});
                        }} defaultValue={app.account()}>
                            {
                                profile.accounts.map((acc, ack) => {
                                    if(acc.account_type.toLowerCase() == "live" && !lacc.length) {
                                        lacc = acc.account_name;
                                    }
                                    let lab = acc.account_name.ucwords().split("-").join(" - ");
                                    return (
                                        <option key={"ack-"+ack} value={acc.account_name}>{acc.account_label.length ? acc.account_label : lab}</option>
                                    )
                                })
                            }
                        </select>
                    </div> : null}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Container);